@import "../../../constants/styles.scss";

.btn {
    border: none;
    color: #fff;
    background: linear-gradient(to right, $theme-color, $theme-light-color, $theme-color);
    border-radius: 2rem;
    text-align: center;
    height: 53px;
    line-height: inherit;
    font-size: 17px;
    min-width: 14rem;
    margin-top: 1.5rem;
    transition: transform 0.1s ease-in;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
      cursor: pointer;
      background: linear-gradient(to right, $theme-light-color, $theme-color, $theme-light-color);
      transform: scale(1.02);
    }

    &:active {
      transform: scale(0.98);
    }

    &:disabled {
        background: #D8D8D8;
        cursor: not-allowed;
    }
  }