@import "../../constants/styles.scss";

.card {
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  max-width: 300px;
  text-align: center;
  font-family: arial;
  display: flex;
  flex-direction: column;
  margin: 1.2rem;
  border-radius: 8px;
  /* position: relative; */
  padding: 5px;
  background-color: white;
  transition: transform 1s;
}

.price {
  color: grey;
  font-size: 22px;
}

.card button:hover {
  opacity: 0.7;
}

.image-container {
  overflow: hidden;
  border-radius: 8px;
}

.imgBlog {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
}

.dsr {
  font-size: 10px;
}
.tit {
  font-size: small;
}

.tittle :hover {
  background-color: black;
  color: white;
}
.aut {
  font-size: small;
}

.tag-container {
  width: 100px;
  margin-top: 26px;
  margin-bottom: 16px;
  margin-left: 5px;
  margin-left: 5px;
}
.tag {
  color: var(--theme-ui-colors-alphaDark, $theme-color); //#4f46e5
  background-color: var(
    --theme-ui-colors-alphaLighter,
    $theme-bg-color
  ); //#e0e7ff
  font-size: 12px;
  border-radius: 8px;
  padding: 4px 16px;
  font-weight: 600;
}
.discription {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  line-height: 1.55;
  display: block;
  text-align: left;
  font-size: 20px;
}
.comment {
  text-align: left;
  color: #718096;
  line-height: 1.6;
  max-height: 8.4rem;
  overflow: hidden;
}
.feedback {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}
.fedimg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 3px;
}
.feed-details {
  display: flex;
  justify-content: space-between;
}
.time {
  margin-left: 25px;
  color: var(--theme-ui-colors-omega, #a0aec0);
}
.date {
  color: var(--theme-ui-colors-omega, #a0aec0);
}
.feed-content {
  margin-top: 8px;
  margin-bottom: 5px;
}
.feedname {
  text-align: left;
  margin: 5px;
  color: #718096;
}
.feed-img {
  margin-right: 6px;
}
.main-container {
  margin: 0 16px;
}
.card:hover {
  cursor: pointer;
  transform: translate(0, -10px);
}
.card .imgBlog {
  transition: transform 1s ease 0s;
}
.card:hover .imgBlog {
  transform: scale(1.1);
}

@media only screen and (max-width: 768px) {
  .card {
    margin: 0.5rem;
    flex-direction: row;
    max-width: inherit;

    .image-container {
      min-width: 40%;
      max-width: 40%;
    }

    .tag-container {
      display: none;
    }

    .comment {
      max-height: 6rem;
    }
  }
}

@media only screen and (max-width: 475px) {
  .card {
    margin: 0.5rem;

    .image-container {
      display: none;
    }
  }
}
