@import "../../../constants/styles.scss";

.logo-card-container {
  margin-right: 1.5rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 0.8rem;
  background: white;
  transition: transform 0.5s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

  .logo-container {
    margin: auto;

    .logo-img {
      min-height: 100px;
      max-height: 100px;
      width: 100px;
      // border: 1px solid grey;
    }
  }

  .logo-title {
    font-family: $font-bold;
    margin-top: 0.8rem;
    font-size: 1.2rem;
  }

  &:hover {
    cursor: pointer;
    transform: translate(0, -10px);
  }
}
