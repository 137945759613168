@import "../../constants/styles.scss";
.list-main-container {
  padding: 5rem 0rem 1rem;
  background-color: white;
}
.head-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 35rem;
    height: 37rem;
  }
  .text-container3 {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 5%;
    .acc-container h2 {
      font-family: $font-bold;
      font-weight: 700;
      line-height: 1.55;
      color: #2d3748;
      font-size: 2.25rem;
      max-width: 650px;
      font-size: 2.25rem;
    }
    .wehelpcontainer {
      font-family: $font-bold;
      font-weight: 500;
      font-size: 1.5rem;
      text-align: left;
      color: #718096;
      width: 86%;
      line-height: 1.5;
    }

    .btn-container button {
      border: none;
      color: #fff;
      background: linear-gradient(
        to right,
        $theme-color,
        $theme-light-color,
        $theme-color
      ); //linear-gradient(to right, #a855f7, #6366f1, #a855f7);
      border-radius: 2rem;
      text-align: center;
      height: 53px;
      line-height: inherit;
      font-size: 17px;
      min-width: 14rem;
      margin-top: 1.5rem;
    }
  }
  .main-container3 {
    width: 50%;
    .item-container3 {
      display: flex;
      .left-container3 {
        display: flex;
        flex-direction: column;
        .series-container {
          height: 46px;
          width: 47px;
          background-color: $theme-color; //#6366f1;
          border-radius: 92px;
          border-color: rgba(0, 0, 0, 0.24);
          display: flex;
          justify-content: center;
          align-items: center;
          p.number {
            color: white;
            font-size: 1.5rem;
          }
          p.content {
            text-align: center;
            color: white;
            line-height: 0.5;
            font-size: 1.25rem;
            font-family: Poppins, sans-serif;
          }
        }
      }
      .right-item-container3 {
        margin-left: 3%;
        .heading3 {
          h3 {
            font-weight: 700;
            font-family: $font-bold;
            color: #2d3748;
            margin: 0px;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 1.4rem;
          }
        }
        .r-content-container {
          width: 80%;

          .content {
            color: #718096;
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.4;
            text-align: justify;
            // font-family: $font-bold;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .list-main-container {
    padding: 5rem 0rem 4rem;
  }
  .head-container {
    align-items: center;

    .img-container {
      width: 95%;
      height: 30rem;
      flex-direction: row;

      img {
        width: 22rem;
      }
    }

    .main-container3 {
      .item-container3 {
        .right-item-container3 {
          .r-content-container {
            width: 85%;
            .content {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .head-container {
    flex-direction: column;

    .img-container {
      height: 25rem;
      flex-direction: row;

      img {
        width: 95vw;
      }
    }

    .main-container3 {
      margin-top: 3rem;
      width: 95%;

      .item-container3 {
        margin-top: 1rem;

        .left-container3 {
          .series-container {
            width: 2rem;
            height: 2rem;

            p.number {
              font-size: 1.2rem;
            }
          }
        }

        .right-item-container3 {
          .heading3 {
            h3 {
              font-size: 1.5rem;
              margin-top: 0;
            }
          }
          .r-content-container {
            width: 100%;
          }
          p {
            font-size: 1.15rem;
          }
        }
      }
    }

    .text-container3 {
      width: 95%;
      margin-left: 0%;

      .acc-container h2 {
        font-size: 1.5rem;
      }

      .wehelpcontainer {
        font-family: $font-bold;
        font-weight: 500;
        font-size: 1.15rem;
        align-self: flex-start;
      }

      .btn-container {
        text-align: center;
      }
    }
  }
}
