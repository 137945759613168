@import "../../constants/styles.scss";

.back-video {
  position: absolute;
  bottom: 0;
  z-index: -1;
  right: 0;
  background-color: rgb(25, 25, 59);
  // min-width: 100%;
  // min-height: 100%;
  // transform: translateX(calc((100% - 100vw) / 3));
}

.video-text-content {
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: white;
  margin: 0 3rem;
  h3 {
    font-family: $font-bold;
    font-weight: 700;
    font-size: 60px;
    //   font-family: $font-bold;
    b {
      font-family: $font-bold;
      font-weight: 700;
      //   color: $theme-color;
    }
  }
}

@media (min-aspect-ratio: 16/9) {
  .back-video {
    height: auto;
    width: 100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .back-video {
    height: 100%;
    width: auto;
  }
  .video-text-content {
    h3 {
      font-size: 35px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .video-text-content {
    text-align: center;
  }
}

@media only screen and (max-width: 425px) {
  .video-text-content {
    text-align: left;
  }
}

@media only screen and (max-width: 375px) {
  .video-text-content {
    h3 {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .video-text-content {
    h3 {
      font-size: 25px;
    }
  }
}