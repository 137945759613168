@import "../../constants/styles.scss";

@mixin flex-box($justifyContent, $alignItems) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
}
.main-form-container {
  height: 80vh;
  width: 100%;
  @include flex-box(center, center);
  .content-container {
    @include flex-box(center, center);
    .title-container {
      width: 44%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-family: $font-bold;
        font-size: 2.875rem;
        line-height: 3.625rem;
        font-weight: 700;
      }
      .para {
        font-family: $font-bold;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-top: 28px;
        width: 90%;
      }
    }
    .form-container {
      display: flex;
      justify-content: center;

      .form-fields-container {
        display: flex;
        flex-direction: column;

        .feild-container {
          // background-color: rgb(246, 247, 250);
          width: 100%;
          margin-bottom: 2rem;

          .selectfield {
            border-color: $theme-color;
          }

          .textfields {
            width: 99%;
            font-size: large;
            line-height: 1.5;
            background-color: rgb(246, 247, 250);
            padding: 0 0.5rem;
            border-radius: 4px;
            resize: none;
          }
        }
        .textinfocontainer {
          width: 35vw;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-form-container {
    height: 100%;
    .content-container {
      flex-direction: column;
      .title-container {
        width: 98%;
      }

      .form-container {
        margin-top: 2rem;
        .form-fields-container {
          .textfields {
            width: 97.5%;
          }
          .title-container {
            width: 100%;
          }
          .textinfocontainer {
            width: 95vw;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .main-form-container {
    .content-container {
      .title-container {
        .title {
          font-size: 1.8rem;
          line-height: 1.2;
        }
        .para {
          font-size: 1.2rem;
          line-height: 1.4;
        }
      }
      .form-container {
        .form-fields-container {
          .feild-container {
            margin-bottom: 1.3rem;
          }
        }
      }
      flex-direction: column;
      .textinfocontainer {
        width: 95vw;
      }
    }
  }
}
