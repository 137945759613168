.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

body:not([--scroll=0]) {
  background: aqua;
}