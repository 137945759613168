@import "../../constants/styles.scss";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.mainContainer {
  .back-image-slider {
    position: absolute;

    img {
      width: 100vw;
      height: 100vh;
    }
  }
  .upper-slider-container {
    .box {
      position: relative;
      // width: 379.75px;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.32);
      border: 1px solid transparent;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .parent {
        width: 90%;
        .headers {
          text-align: left;

          h3 {
            text-align: left;
            font-size: 32px;
            font-weight: 900;
            line-height: 1.2;
            color: white;
            font-family: $font-bold;
            margin-bottom: 20px;
          }
        }

        .headers-active {
          margin-bottom: 20px;
          font-family: $font-bold;
          transition-delay: 0s;
        }

        .content {
          position: relative;
          // padding: 10px 15px;
          visibility: hidden;
          font-size: 0;
          opacity: 0;
          // transition: linear;
          // transition-duration: 0.2s;
          overflow: hidden;
          color: white;
          width: 100%;
          max-height: 0px;
          transition: max-height .5s ease-out .15s;

          p {
            font-family: $font-bold;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            max-width: 800px;
            // white-space: pre-wrap;
          }
        }

        .content-active {
          visibility: visible;
          opacity: 1;
          // width: 65%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition-delay: 0s;
          margin-bottom: 30px;
          max-height: 500px;
          transition: max-height .5s ease-in;
        }

        .learn-more {
          position: relative;
          // padding: 10px 15px;
          visibility: hidden;
          opacity: 0;
          transition: 0.6s ease-in-out;

          button {
            border: none;
            background: transparent;
            font-size: 24px;
            font-weight: 600;
            cursor: pointer;
            color: $theme-color;
          }
        }

        .learn-more-active {
          visibility: visible;
          opacity: 1;
          width: 40%;
          transition-delay: 0.3s;
          color: $theme-color;
          margin-bottom: 30px;
          width: 60%;
        }
      }
    }
    .box-active {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.32),
        rgba(0, 0, 0, 0.64)
      );
    }
  }
}

@media only screen and (max-width: 768px) {
  .mainContainer {
    .back-image-slider {
      img {
        height: 70vh;
      }
    }

    .upper-slider-container {
      .box {
        height: 70vh;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .mainContainer {
    .back-image-slider {
      img {
        height: 70vh;
      }
    }

    .upper-slider-container {
      .box {
        height: 70vh;
      }
    }
  }
}
