@import "../../constants/styles.scss";
@use "sass:math";
// @function bgColor($scrollYvalue) {
//     @debug $scrollYvalue;
//     $value: yellow;
//     @if $scrollYvalue = 0 {
//         @return $theme-color;
//     }
//     @else {
//         @while $scrollYvalue > 0 {
//             $value: blue;
//         }
//     }

//     @return $value;
// }
// @mixin bgColor($scrollYvalue) {
//     background-color: $scrollYvalue;
// }

.max-width {
  max-width: 1300px;
  padding: 0 20px;
  margin: auto;
}
.navbar {
  position: fixed;
  width: 100%;
  padding: 30px 0;
  z-index: 999;
  transition: all 0.3s ease;

  .max-width {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    color: #fff;
    font-family: $font-bold;
    font-size: 35px;
    font-weight: 700;

    a {
      cursor: pointer;
      text-decoration: none;
      color: white;
      img {
        width: 2.5rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
      span {
        color: $theme-color;
        color: bgColor(body[--scroll]);
        transition: all 0.3s ease;
      }
    }
  }

  .menu li {
    list-style: none;
    display: inline-block;

    a {
      color: #fff;
      font-family: $font-bold;
      font-size: 18px;
      font-weight: 500;
      margin-left: 25px;
      transition: color 0.3s ease;
      text-decoration: none;

      &:hover {
        color: $theme-color;
      }
    }
  }

  .menu-btn {
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
  }

  &.sticky {
    padding: 10px 0;
    background: $theme-color;

    .logo span {
      color: #fff;
    }

    .menu li {
      a:hover {
        color: #111;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .max-width {
    padding: 0 50px;
  }

  .navbar .menu {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    background: rgb(25, 25, 59);
    padding-top: 80px;
    transition: all 0.3s ease;

    &.active {
      display: none;
    }

    li {
      display: block;

      a {
        display: inline-block;
        margin: 20px 0;
        font-size: 25px;
      }
    }
  }

  .navbar {
    .logo {
      a {
        img {
          width: 2rem;
          height: 1.82rem;
          margin-bottom: 0px;
        }
      }
    }
    .menu-btn {
      display: block;
      z-index: 999;
    }
  }
}

@media only screen and (max-width: 475px) {
  .max-width {
    padding: 0 20px;
  }

  .navbar {
    .logo {
      font-size: 25px;

      a {
        img {
          height: 1.62rem;
          margin-bottom: -3px;
        }
      }
    }
  }
}
