@import "../../constants/styles.scss";

$tabbtngradeint: linear-gradient(
  279.09deg,
  #007aff,
  #2767f8 15.7%,
  #8938e5 51.03%,
  #ff00cf 90.95%
);
.services-container {
  display: flex;
  justify-content: center;
  .heading-container {
    padding: 5rem 1rem;
    width: 40%;
    display: flex;
    flex-direction: column;
    .ourserivce {
      color: #e0e0e0;
      font-size: 100px;
      font-weight: 700;
      line-height: 0.92;
      text-align: right;
      font-family: $font-bold;
      margin-bottom: 1.5rem;
    }
    .heading-tabs-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .headings {
        font-family: $font-bold;
        font-weight: 700;
        font-size: 2rem;
        position: relative;
        padding: 1rem 0;
        border-top: 1px solid grey;
        width: 80%;
        // height: 0.5px;
        &.active::before {
          background-image: $tabbtngradeint;
          content: "";
          height: 6px;
          left: 0;
          position: absolute;
          top: -1px;
          width: 100%;
        }

        &:hover {
          background-color: #f8f8f8;
          cursor: pointer;
        }
      }
    }
  }
  .content-container {
    width: 60%;
    background: #f8f8f8;
    padding: 5rem 5rem 5rem 3rem;
  }
}
@media only screen and (max-width: 768px) {
  .services-container {
    display: flex;
    flex-direction: column;
    .heading-container {
      padding: 5rem 1rem 1rem;
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      .ourserivce {
        font-size: 80px;
        text-align: left;
      }
      .heading-tabs-container {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;

        .headings {
          font-size: 25px;
          padding: 0.5rem 1rem;
          position: relative;
          border-top: 1px solid grey;
          margin-bottom: 1rem;
          &.active::before {
            background-image: $tabbtngradeint;
            content: "";
            height: 4px;
            left: 0;
            position: absolute;
            top: -1px;
            width: 100%;
          }
        }
      }
    }
    .content-container {
      width: 100%;
      padding: 2.5rem 5rem 5rem;
    }
  }
}
@media only screen and (max-width: 475px) {
  .services-container {
    .heading-container {
      .ourserivce {
        font-size: 60px;
      }
      .heading-tabs-container {
        align-items: center;
        margin-bottom: 1rem;
        .headings {
          font-size: 1rem;
          position: relative;
          border: 2px solid rgb(229, 225, 225);
          border-radius: 12px;
          border-radius: 50px;
          padding: 0.5rem 1rem;
          margin: 0 0.2rem;
          &.active {
            background: linear-gradient(#fff, #fff) padding-box,
              $tabbtngradeint border-box;
            border: 2px solid transparent;
            border-radius: 50px;
          }
          &.active::before {
            content: "";
            height: 0px;
            left: 0;
            position: absolute;
            top: -1px;
            width: 100%;
            display: none;
          }
        }
      }
    }
    .content-container {
      width: 100%;
      padding: 2rem 1rem 1rem 1rem;
    }
  }
}
