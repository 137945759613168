@import "../../constants/styles.scss";

.main-footer-container {
  background-color: black;
  padding: 3rem 5.5rem;

  .footer-container {
    display: flex;
    justify-content: space-between;

    .company-name-tag-container {
      width: 80%;
      .comp-name-container {
        color: white;
        font-weight: bolder;
        font-size: 2rem;

        span {
          color: $theme-color;
        }
      }
      .tag-line-container {
        color: white;
        line-height: 1.5;
        margin-top: 1rem;
      }
      .BeBold {
        font-weight: bold;
        color: white;
        margin-top: 0.5rem;
      }
    }
    .middle-item-container-for-tab {
      display: flex;
      justify-content: space-around;
      width: 40%;
      .explore {
        .explore-text {
          font-size: 1rem;
          color: white;
        }
      }
    }

    .main-follow-container {
      .follow-heading {
        color: white;
      }
      .list-item-main-container {
        margin-top: 1rem;
      }
      .list-item-container {
        line-height: 1.8;
        display: flex;
        align-items: center;
        .icon-container {
          .fa-brands {
            color: white;
          }
        }
        .item-name-container {
          color: white;
          margin-left: 0.5rem;
        }
      }
    }
    .other-details-container {
      display: flex;

      .middle-item-container-for-tab {
        .address {
          color: white;

          .heading {
            margin-bottom: 1rem;
            color: $theme-color;
            text-transform: uppercase;
          }
          .visit-heading {
            color: white;
          }

          .visit-address {
            margin-bottom: 1.7rem;
            line-height: 1.5;
            font-size: 0.9rem;
          }

          .business-enq {
            line-height: 1.8;
            font-size: 0.9rem;

            a {
              text-decoration: none;
              color: white;

              &:hover {
                color: $theme-color;
              }
            }
          }
        }
      }
      .other-links-container {
        display: flex;

        .links-container {
          color: white;
          margin-left: 3rem;

          .link-heading {
            color: $theme-color;
            margin-bottom: 1rem;
            text-transform: uppercase;
          }
          .list-link {
            list-style: none;
            line-height: 1.6rem;
            font-size: 0.9rem;

            a {
              text-decoration: none;
              color: white;
              cursor: pointer;

              &:hover {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }

  .other-msg-container {
    color: grey;
    text-align: center;
    font-size: small;
    margin: 2.5rem 0 0.3rem;

    .hor-line {
      margin: auto;
      margin: 1.5rem 0 0.3rem;
      border: 0.11px solid grey;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-footer-container {
    padding: 3rem 3.5rem;

    .footer-container {
      display: flex;
      flex-direction: column;
      .company-name-tag-for-media-query {
        display: flex;
        justify-content: center;
        .company-name-tag-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .other-details-container {
        margin-top: 2rem;

        .middle-item-container-for-tab {
          display: flex;
          justify-content: space-between;
          width: 40%;
          border-right: 1px solid grey;

          .address {
            width: 75%;
          }
        }

        .other-links-container {
          .links-container {
            margin-left: 2rem;
          }
        }
      }
    }

    .other-msg-container {
      .hor-line {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 475px) {
  .main-footer-container {
    padding: 2.5rem 1rem 1rem;

    .footer-container {
      .other-details-container {
        display: flex;
        flex-direction: column;

        .middle-item-container-for-tab {
          width: 100%;
          border: none;

          .address {
            width: 100%;
          }
        }

        .other-links-container {
          margin-top: 1.5rem;

          .links-container {
            margin-left: 0rem;
            width: 100%;
          }
        }
      }
    }
  }
}
