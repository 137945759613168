// Variables for breakpoints
$breakpoint-xs: 480px;  // Extra small devices (phones)
$breakpoint-sm: 768px;  // Small devices (tablets)
$breakpoint-md: 992px;  // Medium devices (desktops)
$breakpoint-lg: 1200px; // Large devices (large desktops)

$theme-color: crimson;
$theme-light-color: #ff003f;
$theme-bg-color: rgba(220, 20, 60, 0.1);
$font-regular: "Roboto", sans-serif;
$font-bold: "Poppins", sans-serif;

// Mixin for media queries
@mixin respond-to($breakpoint) {
    @if $breakpoint == xs {
        @media (max-width: $breakpoint-xs) { @content; }
    } @else if $breakpoint == sm {
        @media (max-width: $breakpoint-sm) { @content; }
    } @else if $breakpoint == md {
        @media (max-width: $breakpoint-md) { @content; }
    } @else if $breakpoint == lg {
        @media (max-width: $breakpoint-lg) { @content; }
    }
}

.authorizationContainer {
    .mainContent {
        background-color: #001628;
        color: white;
        padding: 8rem 6rem;
        text-align: justify;

        .footerContent {
            margin: 1.5rem 0;
        }
    }
}

@include respond-to(sm) {
    .authorizationContainer {
        .mainContent {
            padding: 8rem 1.4rem;
            font-size: small;

            h2 {
                font-size: 1.2rem;
                text-align: left;
                line-height: 1.6rem;
            }

            ul {
                li {
                    line-height: 1.2rem;
                }
            }

            .footerContent {
                line-height: 1.2rem;
            }
        }
    }
}

@include respond-to(md) {
    .authorizationContainer {
        .mainContent {
            padding: 8rem 3.2rem;
            font-size: small;

            h2 {
                font-size: 1.2rem;
                text-align: left;
                line-height: 1.6rem;
            }

            ul {
                li {
                    line-height: 1.2rem;
                }
            }

            .footerContent {
                line-height: 1.2rem;
            }
        }
    }
}