@import "../../constants/styles.scss";

.title-container-box {
  margin-bottom: 1rem;
  h1 {
    font-family: $font-bold;
    font-size: 25px;
  }
  h3 {
    font-size: large;
  }
}

.detail-container {
  margin-bottom: 3rem;
  line-height: 1.7rem;

  p {
    font-family: $font-bold;
    font-weight: 500;
    font-size: 1.2rem;
  }
}

.tech-container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  .logo-card-container {
    margin-right: 1.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 0.8rem;
    background: white;
    transition: transform 0.5s;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

    .logo-container {
      margin: auto;

      .logo-img {
        max-height: 100px;
        width: 100px;
        // border: 1px solid grey;
      }
    }

    .logo-title {
      margin-top: 0.8rem;
      font-size: 0.8rem;
    }

    &:hover {
      cursor: pointer;
      transform: translate(0, -10px);
    }
  }
}

@media only screen and (max-width: 768px) {
  .tech-container {
    justify-content: center;

    .logo-card-container {
      .logo-container {
        .logo-img {
          max-height: 100px;
          width: 100px;
        }
      }

      .logo-title {
        margin-top: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 475px) {
  .tech-container {
    justify-content: space-evenly;

    .logo-card-container {
      margin: 1rem 0.5rem;

      .logo-container {
        margin-right: auto;

        .logo-img {
          max-height: 100px;
          width: 85px;
        }
      }

      .logo-title {
        font-size: 0.7rem;
      }
    }
  }
}